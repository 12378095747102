<app-header/>

<app-navigation/>

<div class="py-5"></div>

<main>
  <app-about-me/>

  <app-book-recommendations [id]="navigationEntries.bookRecommendations.id"/>

  <app-vita [id]="navigationEntries.vita.id"/>

  <app-favorites [id]="navigationEntries.favorites.id"/>
</main>

<app-back-to-top-button/>

<ng-container #modalContainer/>
