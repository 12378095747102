<article [ngStyle]="{
  '--book-index': bookIndex(),
  '--book-backface-color': bookDetails().backfaceColor,
  '--book-page-padding': '1rem'
}">
  <ul>
    <li
      [ngClass]="{'before:shadow-[inset_0_0_0_transparent]!': isBookCoverOpen()}"
      class="group/item relative inline-block text-dark-void before:absolute before:left-0.5 before:top-0 before:z-31 before:block before:h-full before:w-0.5
             before:bg-transparent before:shadow-[inset_0_0_1px_--theme(--color-dark-void-dark/0.7)] before:transition-all before:duration-500 before:ease-[ease]">
      <img
        [ngSrc]="bookDetails().cover.imagePath" [width]="bookWidth()" [height]="bookDetails().cover.height"
        disableOptimizedSrcset
        [loaderParams]="{imageFormat: 'webp'}" priority
        [ngClass]="{'[transform:perspective(1500px)_rotateY(-150deg)]! shadow-[3px_1px_7px_--theme(--color-dark-void-dark/0.5)]!': isBookCoverOpen()}"
        class="relative z-[calc(21+var(--book-index))] origin-[0_70%] cursor-pointer rounded-[2px_3px_3px_2px] opacity-100
               shadow-[1px_1px_4px_--theme(--color-dark-void-dark/0.5)] transition-all duration-500 ease-[ease] [backface-visibility:hidden]
               group-hover/item:shadow-[3px_1px_7px_--theme(--color-dark-void-dark/0.5)] group-hover/item:[transform:perspective(1500px)_rotateY(-22deg)]"
        [alt]="bookDetails().details.title + ': ' + bookDetails().details.subtitle"
        (click)="toggleCoverOpen()" (keyup)="toggleCoverOpen()" tabindex="0"/>
      <div [ngClass]="{'[transform:perspective(1500px)_rotateY(-300deg)]! sm:[transform:perspective(1500px)_rotateY(-330deg)]! ml-[1px]!': isBookCoverOpen()}"
           class="absolute -left-full top-0 z-[calc(20+var(--book-index))] size-full origin-[100%_70%] cursor-pointer
                  rounded-[3px_0_0_3px] border-[3px] border-r-0 border-solid border-(--book-backface-color)
                  bg-snow
                  shadow-[-10px_0_10px_--theme(--color-dark-void-dark/0.2),inset_40px_0_60px_--theme(--color-dark-void-dark/0.15),0_0_0_1px_--theme(--color-snow/0.15)]
                  transition-all duration-500 ease-[ease] [backface-visibility:hidden] [transform:perspective(1500px)_rotateY(-180deg)] group-hover/item:[transform:perspective(1500px)_rotateY(-202deg)]"
           (click)="toggleCoverOpen()" (keyup)="toggleCoverOpen()" tabindex="0">
        <div class="flex h-full items-center justify-center overflow-hidden p-[calc(2*var(--book-page-padding))] text-center sm:p-(--book-page-padding)">
          <div class="book-cover">
            <a [href]="bookDetails().url" target="_blank" (click)="$event.stopPropagation()">Zum Buch von {{ bookDetails().details.author }}</a>
          </div>
        </div>
      </div>
      <div class="absolute left-0 top-0 z-10 size-full p-px">
        <ul class="absolute left-0 top-0 z-0 ml-px size-full
                   *:absolute *:size-full *:rounded-[2px_3px_3px_2px] *:border *:border-l-0 *:border-solid
                   *:border-snow *:bg-snow
                   *:shadow-[1px_1px_1px_1px_--theme(--color-dark-void-dark/0.2)] after:absolute after:bottom-[-9px] after:left-[-2px]
                   after:z-0 after:block after:size-0 after:border-[5px] after:border-solid after:border-b-transparent after:border-l-transparent after:border-r-(--book-backface-color)
                   after:border-t-(--book-backface-color)">
          <li class="left-0 top-0 z-5 overflow-hidden p-(--book-page-padding)
                     shadow-[1px_1px_1px_1px_--theme(--color-dark-void-dark/0.2),inset_7px_0_10px_-5px_--theme(--color-dark-void-dark/0.15),inset_0_0_60px_--theme(--color-dark-void-dark/0.1)]!">
            <div class="*:leading-none">
              <h3 class="text-center text-lg">{{ bookDetails().details.title }}</h3>
              <h4 class="text-center text-sm">{{ bookDetails().details.subtitle }}</h4>
              <div class="divider mx-auto my-3 max-w-[50%]"></div>
              <p class="text-sm">{{ bookDetails().details.summary }}</p>
            </div>
            <span class="absolute bottom-0 right-0 mb-2 me-2 text-xs">1</span>
          </li>
          <li class="left-[2px] top-[2px] z-4"></li>
          <li class="left-[4px] top-[4px] z-3"></li>
          <li class="left-[6px] top-[6px] z-2"></li>
        </ul>
      </div>
      <div class="absolute left-[9px] top-[8px] z-1 size-full
                  rounded-[2px_3px_3px_2px] bg-(--book-backface-color)"></div>
    </li>
  </ul>
</article>
